.App {
  text-align: center;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  display: none;
}
